/** Header **/
.headerStructure {
  margin: 0;
  position: relative;
  background: #28495A;
  min-height: 73px;
  position: fixed;
  width: 100vw;
  z-index: 1000;

  .headerContentWrapper {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    padding: 17px 24px;
    display: flex;
    align-items: center;
    position: relative;
  }

  .headerLogoWrapper {
    img {
      height: 52px;
    }

    margin-top: -5px;
    padding-right: 20px;
  }
}

// Hamburger mobile menu
.hamburger {
  display: none;
  position: absolute;
  transform: rotate(90deg);
  font-size: 2rem;
  cursor: pointer;
  padding: 10px 6px;
  margin-right: 1rem;
  font-weight: 900;
  color: #fff;

  &:hover {
    color: #5b5b5b;
  }
}

.hamburgerClose {
  display: none;
  position: relative;
  cursor: pointer;
}

.hamburgerCloseOpened {
  display: block;
}

.disabledLink {
  pointer-events: none;
  color: #59595c !important;
}

.pressReleaseWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 40px;
  margin: auto !important;
  padding: 8px 16px;
  color: #fff;
  background: -o-linear-gradient(20deg, #096edb 0%, #732dd9 100%), #732dd9;
  background: linear-gradient(70deg, #096edb 0%, #732dd9 100%), #732dd9;
  font-size: 14px;

  .pressReleaseLabel {
    margin-right: 16px;
    padding: 4px 8px;
    letter-spacing: 1px;
    text-transform: uppercase;
    border: 1px solid rgba(255, 255, 255, .5);
    border-radius: 4px;
    font-size: 10px;
    font-weight: 700;
    line-height: 15px;
  }

  .pressReleaseTitle {
    &::after {
      display: inline-block;
      width: 5px;
      height: 5px;
      margin: 0 6px;
      content: "\a";
      border-radius: 50%;
      background: #fff;
    }
  }

  .pressReleaseLink {
    a {
      text-decoration: underline !important;
      color: #fff !important;
      font-weight: 700;
      line-height: 20px;
    }
  }
}

.headerMenuWrapper {
  display: block;
}

.headerMenuWrapperHidden {
  display: none;
}

.loginLinkWrapper {
  margin-left: auto;

  .loginLinkItems {
    display: flex;
    align-items: center;

    .websiteDarkBtnOutline {
      display: block;
      min-width: 160px;
      padding: 10px 20px;
      cursor: pointer;
      text-align: center;
      color: #fff;
      border: 1px solid #fff;
      border-radius: 360px;
      background-color: rgba(0, 0, 0, 0);
      font-size: 16px;
      font-weight: 700;
      font-style: normal;
      line-height: 20px;
    }

    .websiteGoldBtnOutline {
      display: block;
      min-width: 120px;
      padding: 5px 14px;
      cursor: pointer;
      text-align: center;
      color: rgb(40, 73, 90);
      border-radius: 360px;
      background-color: rgb(231, 197, 104);
      font-size: 14px;
      font-weight: 700;
      font-style: normal;
      line-height: 20px;
    }

    .websiteDarkBtn {
      display: block;
      min-width: 160px;
      padding: 11px 20px;
      cursor: pointer;
      text-align: center;
      color: #fff;
      border: 1px solid #fff;
      border-radius: 360px;
      background-color: #fff;
      font-size: 16px;
      font-weight: 700;
      font-style: normal;
      line-height: 20px;
    }

    .websiteClearBtn {
      display: block;
      min-width: 120px;
      padding: 11px 0px;
      cursor: pointer;
      text-align: center;
      color: #fff;
      border: none;
      border-radius: 360px;
      background-color: transparent;
      font-size: 16px;
      font-weight: 700;
      font-style: normal;
      line-height: 20px;
    }

    .signinWrapper {
      display: none;
    }

    .signinWrapperOpened {
      display: block;
      position: absolute;
      border: 1px solid;
      border-top-color: rgb(231, 197, 104);
      border-radius: 6px;
      background-color: #1d3640;
      top: 62px;
      right: -25px;
      left: auto;
      min-width: 175px;
      border-radius: 8px;
      -webkit-box-shadow: 0 4px 6px 0 rgba(0, 0, 0, .15);
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, .15);
      z-index: 10;
      padding: 0 !important;

      li {
        padding-top: 9px;
        padding-bottom: 9px;

        &:hover {
          background-color: #132931;
          border-radius: 4px;

          a {
            color: #e7c568;
          }
        }

        a {
          color: #fff;
          font-size: 14px;
          font-weight: normal;
          padding: 12px 10px;
          line-height: 20px;
        }
      }
    }

    .signInArrow {
      position: absolute;
      top: -50px;
      left: 55px;
    }
  }
}

.topMenuLinkInitialWrapper {
  display: flex;

  .topMenuArrow {
    padding: 0;
  }
}

.topMenuLinkWrapper {
  padding-left: 12px !important;
  padding-right: 12px !important;

  .topMenuLink {
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    position: relative;

    &:hover {
      color: #e7c568;
      text-decoration: none;
    }
  }

  a[clicked="true"] {
    color: #e7c568;
  }
}

.dropDownWrapper {
  position: absolute;
  top: 80px;
  left: 0;
  padding: 0;
  z-index: 100;
  width: 100vw !important;
  max-width: 1440px;
}

.subMegaMenu {
  position: static;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  padding: 35px 30px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #1F3A45;
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
  border: 1px solid #1E3743;
  border-top-color: rgb(231, 197, 104);

  .shaded {
    background-color: #f3f5f8 !important;
    margin: -23px;
    padding-top: 25px;
  }

  .headerTeal {
    background-color: #28495A;
    margin-top: -34px;
    margin-bottom: -34px;
    margin-right: -31px;
    width: 30%;
    padding: 25px;
    padding-top:50px;
    //padding-left: 5px;

    .headerLabel {
      padding-left: 27px !important;
    }
  }

  .headerLabel {
    margin-bottom: 12px;
    letter-spacing: 1.5px;
    color: #fff !important;
    font-size: 14px !important;
    font-weight: 500;
    line-height: 24px;
    text-transform: uppercase;
  }

  .menuLinkWrapper {
    display: flex;

    .menuLinkSection {
      padding-right: 10px;
      min-width: 250px;

      .menuLink {
        text-transform: none;
        font-size: 13px;
        font-weight: 400;
        padding: 8px;
        padding-right: 60px;

        &:hover {
          border-left: 1px solid #e9c369;
          background-color: #132931;
          border-radius: 4px;
          margin-left: -1px;
        }

        a {
          color: #fff;
          font-weight: 400;

          &:hover {
            text-decoration: none;
            color: #e7c568;
          }

          .link-title:hover {
            color: #fff;
          }
        }

        .linkDescription {
          padding: 0;
          margin-top: 4px;
          white-space: normal;
          border: unset;
          font-size: 11px;
          color: #d1d2d3;
        }

        .linkDescriptionResources {
          width: calc(100% - 116px);
          display: -webkit-box;
          overflow: hidden;
          margin-top: 8px;
          margin-bottom: 16px;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          color: #fff;
          font-size: 18px;
          line-height: 21px;
        }

        .arbitraryFloat {
          float: left;
          width: 100px;
          height: 1px;
          margin-right: 15px;
        }

        .linkPicture {
          float: left;
          margin-right: 15px;

          img {
            width: 100px !important;
            height: 100px;
            border-radius: 20px;
            background-color: #efefef;
          }
        }
      }

      .menuLink.noHover {
        &:hover {
          border-left: inherit;
          background-color: inherit;
          border-radius: inherit;
          a {
            color: #FFF;
          }
        }
      }

      .menuLinkCtaLink {
        .linkTitle {
          &::after {
            content: "→";
            margin-left: 5px;
          }
        }

        a {
          font-weight: 700;
        }
      }

      .ctaTitle {
        margin-top: 10px;
        margin-bottom: 20px;
        font-weight: 700;

        &::after {
          content: "→";
          margin-left: 5px;
          color: #e9c369;
        }
      }

      .eyebrow {
        display: -webkit-box;
        overflow: hidden;
        margin: 0;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #e7c568;
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        line-height: 24px;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .pictureSectionMaxWidth {
        max-width: 450px;
        padding-right: 0;
      }
    }
  }
}

.section {
  max-width: 31.20567%;
  min-height: 18.75px;
  margin: 3.125px 10.266px;
  padding-top: 15px;
  padding-right: 15px;
}

.triangleContainer {
  position: absolute;
  width: 20px;
  top: 30px;
  left: 10px;
  margin: auto;
  text-align: center;
  z-index: 110;
  height: 10px;
}

.chevronDownSigninVisible {
  position: absolute;
  visibility: visible;
  top: 1rem;
}

.chevronDownMyAccountVisible {
  position: absolute;
  visibility: visible;
  top: 1rem;
}

.chevronUpSignInVisible {
  position: absolute;
  visibility: visible;
  top: 1rem;
}

.chevronUpMyAccountVisible {
  position: absolute;
  visibility: visible;
  top: 1rem;
}


.chevronDownSigninHidden {
  position: absolute;
  visibility: hidden;
  top: 1rem;
}

.chevronDownMyAccountHidden {
  position: absolute;
  visibility: hidden;
  top: 1rem;
}

.chevronUpSignInHidden {
  position: absolute;
  visibility: hidden;
  top: 1rem;
}

.chevronUpMyAccountHidden {
  position: absolute;
  visibility: hidden;
  top: 1rem;
}

.chevronDownSigninClickedVisible {
  position: absolute;
  visibility: visible;
  top: -8px;
  right: 0px;
}

.chevronUpSignInClickedVisible {
  position: absolute;
  visibility: visible;
  top: -8px;
  right: 0;
}

// Footer
.footer {
  background-color: rgb(35, 31, 32);
  padding: 44px 44px 24px 29px;
  color: #fff;

  .footerContainer {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;

    .footerRegion {
      position: relative;

      .footerNavMain {
        .footerMenuHeader {
          display: block;
          margin-bottom: 12px;
          color: #fff;
          font-size: 16px;
          font-weight: 700;
          line-height: 28px;
        }

        .footerMenuItem {
          margin-bottom: 4px;
          line-height: 2.32142857em !important;

          a {
            display: block;
            line-height: 24px;
            color: #FFF;
            font-size: 14px;
            font-weight: 400;
          }
        }

        .socialMediaUl {
          display: flex;
        }
      }

      .footerBottom {
        clear: both;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: baseline;
        padding: 20px 0 10px 0;
        padding-top: 130px;

        .footerBottomMenuItem {
          color: #FFF;
          font-size: 12px;
          line-height: 16px;

          a {
            color: #FFF;
            font-size: 12px;
            line-height: 16px;
          }

          &:after {
            content: ' | ';
            color: #FFF;
            padding: 0 10px;
          }

          &:last-child {
            &:after {
              content: '';
            }
          }
        }
      }
    }
  }

  .footerLogoWrapper {
    margin-bottom: 45px;
  }
}

.noShow {
  display: none;
}

.width300 {
  width: 300px;
}


@media screen and (min-width: 1200px) {
  .footerContainer {
    width: 1170px;
  }
}

@media screen and (min-width: 992px) {
  .footerContainer {
    width: 970px;
  }
}

@media screen and (min-width: 768px) {
  .footerContainer {
    width: 750px;
  }
}

.siteLogo {
  img {
    width: 100px;
    height: 100px;
  }
}

.searchNavWrapper {
  .searchIconWrapper {
    text-align: center;
    width: 38px;
    height: 38px;
    margin-top: 3px;
    margin-right: 24px;
    cursor: pointer;
    color: #fff;
    border: 1px solid #d1d2d3;
    border-radius: 50%;
    background: transparent;
  }
}

.searchInputSubmitBtn {
  position: absolute;
  top: 2px;
  left: 10px;
  color: #71768b;
}

.searchIcon {
  display: var(--fa-display, inline-block);
  height: 1em;
  overflow: visible;
  vertical-align: -0.55em;

  path {
    stroke: #fff !important;
    stroke-width: 5% !important;
  }
}

.searchIconDark {
  display: var(--fa-display, inline-block);
  height: 1em;
  overflow: visible;
  vertical-align: -0.55em;
  font-size: 17px;
  color: #fff;

  path {
    stroke: rgba(40, 73, 90, 0) !important;
    stroke-width: 8% !important;
  }
}

.searchCancelIcon {
  display: var(--fa-display, inline-block);
  height: 1.6em;
  overflow: visible;
  vertical-align: -0.85em;

  path {
    stroke: #fff !important;
    stroke-width: 5% !important;
  }
}

.headerSearchBoxWrapper {
  display: none;
}

.headerSearchBoxWrapperOpened {
  display: block;
  position: absolute;
  position: absolute;
  right: 430px;
  width: 600px;
  animation: slide-out-search 500ms;
  button {
    border: none;
    background: #fff;
  }
}

.searchInputBox {
  width: 70%;
}

// Mobile menu
@media screen and (max-width: 1139px) {
  .siteLogo {
    position: absolute;
    left: 85px;
    top: 10px;
  }

  .headerSearchBoxWrapperOpened {
    left: 10px;
    right: unset;
    width: 70%;
    animation: slide-out-search-mobile 500ms;
  }

  .section {
    max-width: 100%;
  }

  .dropDownWrapper {
    position: relative;
    top: 30px;
  }

  .loginLinkWrapper {
    height: 50px;

    .loginLinkItems {
      display: none;
      position: absolute;
      left: 0px;
      top: 70px;
      width: 100%;
      padding-left: 10px;
      height: 60px;

      .websiteDarkBtnOutline,
      .websiteDarkBtn {
        font-size: 14px;
      }
    }

    .loginLinkItemsOpened {
      display: flex;
      justify-content: space-between;
      position: absolute;
      left: 0px;
      top: 70px;
      width: 100%;
      padding: 0 30px;
      z-index: 100;
      background: #28495A;
      animation: slide-in 500ms;

      .websiteDarkBtnOutline,
      .websiteDarkBtn {
        font-size: 14px;
      }

      .websiteDarkBtn {
        margin-top: 10px;
        margin-right: 5px;
      }
    }

    .signinWrapperOpened {
      position: fixed !important;
      top: 26px !important;
      left: 0 !important;
      width: 100%;
      height: 100%;
      border: 0 !important;
      overflow: hidden !important;
      width: 70%;
      animation: slide-in 500ms;

      &::before {
        content: 'Sign In';
        padding: 50px 20px;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        font-style: normal;
        margin-bottom: 16px;
      }

      &::after {
        position: absolute;
        content: '←';
        padding: 50px 20px;
        top: -66px;
        font-size: 26px;
        cursor: pointer;
      }
    }
  }

  .websiteDarkBtnOutline {
    display: none;
  }

  .hamburger {
    display: block;
  }

  .hamburgerClosed {
    display: none;
  }

  .headerMenuWrapper {
    display: none;
  }

  .headerMenuWrapperOpened {
    display: block;
  }

  .menuLinkSection {
    padding: 0 !important;
  }

  .topMenuLinkInitialWrapper {
    position: absolute;
    display: block;
    background-color: #28495A;
    z-index: 11;
    top: 130px;
    left: 0;
    width: 100%;
    height: 100vh;
    animation: slide-in 500ms;

    .topMenuLinkWrapper {
      border: 0;
      border-top: 1px solid #244251;
      padding: 16px 0 !important;

      .topMenuLink {
        width: 100%;
        font-size: 14px !important;
        padding: 0 16px;
        display: block;

        &::after {
          content: '';
          display: inline-block;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          position: absolute;
          right: 15px;
          top: 50%;
          margin-top: -11px;
          border-top: 5px solid #ECD189;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
        }
      }
    }
  }

  .subMegaMenu {
    display: block;
    top: 40px;
    left: 0;
    border: 0;
    border-top: 1px solid rgb(231, 197, 104);
    width: 100%;
    background-color: rgb(32, 60, 72);
    z-index: 100;
    max-height: 600px;
    overflow-y: auto;

    .menuLinkWrapper {
      display: block;
    }

    .shaded {
      margin: unset;
    }
  }

  .triangleContainer {
    display: none;
  }

  .footerNavMain {
    padding-left: 0 !important;

    ul {
      display: block;
      padding: 0 !important;

      li {
        margin-bottom: 30px;
      }
    }
  }

  .footerBottom {
    padding-top: 0px !important;
    justify-content: start !important;

    ul {
      display: block;
    }

    .footerBottomMenuItem {
      padding: 10px 0;

      &:after {
        content: '' !important;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .headerLogoWrapper {
    margin-top: -5px;
    margin: 0 auto;
    padding-right: 0px;
  }

  .siteLogo {
    position: static;
  }

  .searchIconWrapper {
    position: absolute;
    right: 0px;
    top: 15px;
  }

  .loginLinkWrapper {
    margin-left: 0;
    ;
  }

}

@keyframes slide-out-search {
  from {
    transform: translateX(10%);
  }

  to {
    transform: translateX(0%);
  }
}

@keyframes slide-out-search-mobile {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
}

@keyframes slide-in {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0%);
  }
}
