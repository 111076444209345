body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-opacity-75 {
  --tw-bg-opacity: 0.75;
}

.transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.fw-600 {
  font-weight: 600;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.border-gray-1 {
  --tw-border-opacity: 1;
  border: solid;
  border-color: hsla(201, 7%, 92%, var(--tw-border-opacity));
}

.border-b {
  border-bottom-width: 1px;
}

.flexible {
  display: flex;
}
@media (min-width: 640px) {
  .sm\:align-middle {
    vertical-align: middle;
  }

  .sm\:max-w-sm {
    max-width: 24rem;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
.relative {
  position: relative;
}
.hidden {
  display: none !important;
}
ul {
  list-style: none;
}
.chevron-up {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}
.pe-2 {
  padding-inline-end: 0.5rem;
}
.mr-\[15px\] {
  margin-right: 15px;
}
#loginLinkItemsId {
  margin-bottom: 0;
}
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mr-3 {
  margin-right: 0.75rem;
}
.mar-x-5 {
  margin-left: 3rem;
  margin-right: 3rem;
}
.pr-3 {
  padding-right: 0.75rem;
}
.pl-9 {
  padding-left: 2.25rem;
}
.rounded-md {
  border-radius: 0.375rem;
}
.width300 {
  width: 300px;
}
.w-full {
  width: 100%;
}
.flex {
  display: flex;
}
.justify-between {
  justify-content: space-between;
}
.button {
  text-transform: none;
}
#footer ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 1139px) {
  .signinbutton,
  .talktoexpert {
    display: block;
    width: 47%;
  }
  .signinbutton {
    border: 1px solid #fff;
    border-radius: 360px;
    > span {
      padding: 4px 0 !important;
    }
  }
  .talktoexpert {
    margin-right: 0;
    a {
      margin-right: 0;
    }
  }
  .chevron-wrapper {
    display: none !important;
  }
}
.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.search-input::-webkit-input-placeholder {
  font-style: italic;
}

.search-input:-moz-placeholder {
  font-style: italic;
}

.search-input::-moz-placeholder {
  font-style: italic;
}

.search-input:-ms-input-placeholder {
  font-style: italic;
}
.border-b-teal {
  border-bottom: 1px solid #244d5c !important;
  padding-left: 0 !important;
  padding-bottom: 6px !important;
  margin-bottom: 12px;
}
.border-b-teal:hover {
  border-left: none !important;
  background-color: none !important;
}
.border-b-teal > a:hover,
.border-b-teal > a:hover > div {
  color: #fff;
}
.blurred:before {
  position: fixed;
  z-index: 3;
  width: 100%;
  height: 100%;
  content: "";
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background: rgba(39, 70, 83, 0.1019607843);
}
#page-wrapper {
  padding-top: 73px;
}
