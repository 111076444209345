/* Thank You Download Page CSS */

a {
  text-decoration: none !important;
}
.download-message-blade {
  background-color: #121646;
}

.download-message-banner {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 2rem 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 80rem;
}

#download-message-title {
  font-size: 1.3125rem;
  line-height: 2rem;
  color: rgba(256, 256, 256, var(--tw-text-opacity)) !important;
  font-family: Roboto, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-weight: 500;
  letter-spacing: 0em;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  margin-bottom: 0.5rem;
}
#download-link {
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: #e7c568;
}
.hero-blade {
  padding: 1.5rem 1rem;
  position: relative;
  overflow: hidden;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.hero-blade-background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0px;
  left: 0px;
  background-color: #5e98a3;
  /*background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  --tw-gradient-from: #171845;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(23, 24, 69, 0));
  --tw-gradient-to: #732dd9;*/
}

.pgv-blade-background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0px;
  left: 0px;
  background-color: #1a1c46;
}
.hero-banner {
  font-family: sans-serif;
  margin-left: auto;
  margin-right: auto;
  max-width: 80rem;
  display: flex;
  padding: 2rem 0;
}
.biganimal-logo-wrapper {
  margin-bottom: 10px;
  margin-right: 15px;
}
.hero-banner-text {
  font-family: sans-serif;
  margin-left: 50px;
}
.hero-banner-text-headline {
  font-family: sans-serif;
  font-size: 2.25rem;
  font-weight: 600;
  color: #fff;
  line-height: 2.25rem;
}
.hero-banner-text-sub-headline {
  font-family: sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  color: #fff;
  line-height: 1.25rem;
}
ul.hero-banner-text-list {
  list-style-type: disc;
}
li.hero-banner-text-list-item {
  list-style-type: disc;
  margin-left: -15px;
}
.hero-banner-text-button-wrapper {
  display: flex;
}
.cta-orange a {
  color: #fff;
  border: none;
  background-color: #ff3e00;
  padding: 10px 36px;
  border-radius: 24px;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.4;
}
.cta-teal a {
  color: #fff;
  border: none;
  background-color: #1e91a2;
  padding: 10px 36px;
  border-radius: 24px;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.4;
}

.cta-plain a {
  color: #fff;
  border: none;
  background-color: none;
  padding: 10px 18px;
  border-radius: 24px;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.4;
}
.cta-plain a:hover,
.cta-teal a:hover {
  color: #fff;
  filter: brightness(90%);
}

.click-message {
  margin-bottom: 0px;
  --tw-text-opacity: 1;
  color: rgba(256, 256, 256, var(--tw-text-opacity));
  font-weight: 500;
}
.download-messge a:not([data-whole-card]) {
  position: relative;
  z-index: 2;
}
.categories-gray-block {
  display: inline-block;
  background-color: #e2e2e2;
  padding-right: 20px;
  border-radius: 20px;
}
.experts-button-wrapper {
  max-width: 450px;
  background-color: #ffb41f;
  border-radius: 20px;
  padding: 1rem;
}
.experts-button {
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(9, 110, 219, var(--tw-text-opacity));
}
.expert-button-sub-section {
  text-align: center;
  width: 50%;
}
.expert-button-number {
  font-size: 3rem;
  font-weight: 600;
  color: #000;
  padding: 1rem;
}
.expert-button-text {
  color: #000;
  font-weight: 300;
  padding: 0 0.5rem;
}
.installation-instruction-link {
  font-size: 1rem;
  line-height: 1.5rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0.5rem 1.25rem;
  border-width: 1px;
  border-color: transparent;
  border-radius: 9999px;
  font-weight: 600;
  --tw-text-opacity: 1;
  color: #fff;
  --tw-bg-opacity: 1;
  /*background-color: rgba(255, 255, 255, var(--tw-bg-opacity));*/
}
.css-sfix1y {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.css-1it8pcx {
  gap: 3.5rem;
  grid-template-columns: repeat(9, minmax(0px, 1fr));
  display: grid;
  margin-bottom: 3rem;
}
.css-1se0y8g {
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(9, 110, 219, var(--tw-text-opacity));
  grid-column: span 1 / span 1;
  text-align: center;
  text-decoration: none;
}

.css-ks24f9 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.375rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(205.887, 210.064, 212.313, var(--tw-border-opacity));
  padding: 0.75rem 0px;
  margin-bottom: 0.25rem;
}
.css-nzosuu {
  font-size: 3rem;
  line-height: 1;
  margin-left: auto;
  margin-right: auto;
}
.css-j7rh6c {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.css-1k690hu {
  font-size: 1rem;
  line-height: 1.5rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0.5rem 1.25rem;
  background-color: transparent;
  border: 1px solid;
  border-radius: 9999px;
  font-weight: 600;
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.css-1p0mbyi {
  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
  overflow: hidden;
}
.main-content-body {
  margin-left: auto;
  margin-right: auto;
  max-width: 80rem;
}
.main-content-body-title {
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 600 !important;
  font-family: Roboto, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-weight: 400;
  letter-spacing: 0em;
  width: 100%;
  margin-bottom: 0.5rem;
}
.css-1773qpw {
  margin-top: 0px;
  margin-bottom: 4rem;
}
.css-1a9ptwa {
  display: flex;
  gap: 1.5rem;
}
@media (max-width: 650px) {
  div.css-1a9ptwa {
    display: block;
  }
}
.css-agesuq {
  padding: 2rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(233.172, 235.028, 236.028, var(--tw-border-opacity));
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.5rem;
  flex: 1 1 0%;
}

.main-content-body-sub-title {
  margin-bottom: 2rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  --tw-text-opacity: 1;
  color: rgba(52.173, 57.2781, 60.027, var(--tw-text-opacity));
}
.purple-banner {
  background-color: #732dd9;
  display: flex;
  align-items: center;
  border-radius: 1rem;
  padding: 20px 50px 10px;
}
.teal-banner {
  background-color: #28495a;
  filter: brightness(150%);
  display: flex;
  align-items: center;
  border-radius: 1rem;
  padding: 20px 50px 10px;
}
.ba-link-text {
  color: #fff;
}
.css-7fq1u7 {
  flex-direction: row;
  display: flex;
}
@media (max-width: 650px) {
  .icon-link {
    font-size: 0.8rem;
  }
  .css-7fq1u7 {
    flex-direction: column;
  }
  .css-7fq1u7 .css-ztzo0b {
    width: 100%;
    border-right: none;
  }
}
.css-ztzo0b {
  padding-right: 3rem;
  border-right: 1px solid;
  width: 40%;
  --tw-border-opacity: 1;
  border-color: rgba(233.172, 235.028, 236.028, var(--tw-border-opacity));
}

@media (max-width: 640px) {
  .categories-list-link,
  div.css-75iss5,
  ul.css-10728a9,
  ul.css-10728a9 .css-1na8z7z,
  div.css-1kd4k9u,
  ul.css-1rwe08m {
    padding-left: 0;
  }
  .css-agesuq {
    padding: 1rem;
  }
}
@media (max-width: 800px) {
  .css-75iss5 {
    padding-left: 1rem;
  }
  .css-10728a9 {
    padding-left: 0;
  }
}

.css-10728a9 {
  display: grid;
  gap: 1.5rem 1.25rem;
  -webkit-box-pack: start;
  justify-content: flex-start;
  grid-template-columns: repeat(auto-fit, minmax(250px, auto));
}
.sub-banner-text {
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(9, 110, 219, var(--tw-text-opacity));
  display: flex;
  flex-direction: row;
  gap: 1rem;
  border-radius: 0.5rem;
  -webkit-box-align: center;
  align-items: center;
  padding: 0px;
  padding-right: 1rem;
  margin-top: 10px;
  text-decoration: none;
}

.categories-list-link {
  padding-left: 1rem;
  padding-right: 1rem;
  text-decoration: none;
}
.categories-title {
  padding-left: 2rem;
  padding-right: 1rem;
  text-decoration: none;
  text-transform: uppercase;
}
.css-t0hzbj {
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  justify-content: center;
}

.categories-list-link {
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: #000;
  font-weight: 600;
}
.categories-gray-block-ul {
  padding-left: 1rem;
}
.psql-support {
  display: flex;
  flex-flow: row wrap;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-top: 2rem;
}
.psql-support-title {
  font-size: 2rem;
  line-height: 2rem;
  font-family: Roboto, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-weight: 600;
  letter-spacing: 0em;
  margin-bottom: 0.5rem;
}
.psql-covered {
  clear: both;
  margin-top: 3rem;
}
.psql-covered-title {
  font-size: 2rem;
  line-height: 2rem;
  font-family: Roboto, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-weight: 600;
  letter-spacing: 0em;
  margin-bottom: 2rem;
}
.psql-covered-link-wrapper {
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  justify-content: center;
}
.marginx-auto {
  margin: 0 auto;
}
.width125 {
  width: 125px;
}

@media (min-width: 1024px) {
}

@media screen and (min-width: 768px) {
  .width-50-big {
    width: 50% !important;
  }
}

@media (min-width: 640px) {
  .mb-0-640 {
    margin-bottom: 0x;
  }
}
.mb-1-640 {
  margin-bottom: 1rem;
}

.css-byrywa {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(34, 31, 31, var(--tw-text-opacity));
  margin-right: 3rem;
  margin-bottom: 1rem;
}

.css-18h88zt {
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgba(52.173, 57.2781, 60.027, var(--tw-text-opacity));
}

.st6 {
  fill: #266ab4;
}
/*** Custom css ***/

@media (max-width: 780px) {
}
.icon-link {
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(9, 110, 219, var(--tw-text-opacity));
  text-align: center;
  text-decoration: none;
  width: 21%;
}
@media (max-width: 960px) {
  .icon-link {
    font-size: 0.8rem;
  }
}
.break-word {
  word-wrap: break-word;
}

@media (max-width: 650px) {
  .sub-banner-text {
    padding-left: 0;
  }
}
