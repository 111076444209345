.token-flex {
  display: flex;
}
.repo-justify-end {
  justify-content: flex-end;
}
p.first-p {
  margin-bottom: 50px;
}
.card-row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.card-col-6 {
  flex: 0 0 auto;
  width: 50%;
}

@media (max-width: 1007px) {
  .token-flex {
    flex-direction: column;
  }
  p.first-p {
    margin-bottom: 80px;
  }
}
@media (max-width: 790px) {
  p.first-p {
    margin-bottom: 100px;
  }
}
@media (max-width: 750px) {
  .card-row {
    flex-direction: column;
  }
  .card-col-6 {
    width: 100%;
  }
  p.first-p {
    margin-bottom: 20px;
  }
}
