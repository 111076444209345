// Bootstrap is loaded so you may need !important sometimes :)
@media screen and (max-width: 650px) {
  body,
  html {
    height: 100%;
    width: 100vw;
    overflow-x: scroll;
    footer {
      position: fixed;
      z-index: 2;
      bottom: 0;
      width: 100vw;
      overflow-x: scroll;
      padding: 5px;
      .container {
        max-width: 100%;
        padding: 0;
        margin: 0;
      }
      #block-footernavmain-2,
      #block-socialnavfooter
       {
        display: none;
      }
      .copyright-cta .menu {
        margin-bottom: 5px;
        padding:5px 0 0 0;
        .menu__item {
          margin-bottom: 0;
          padding-bottom: 0;
          padding: 0 5px;
          &:first-child {
            margin-right: 5px;
          }          
        }
      }
    }
    .mobile-container.container {
      margin: 0;
      padding: 0;
    }
    .mobile-w100 {width: 100%;}
    .mobile-block {display: block;}
    .hide-mobile {display: none;}
  }
}

html {
  body {
    font-family: "Roboto", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    //font-weight: 300;
    line-height: 24px;

    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
      

    .zindex-1 {
      z-index: 1;
    }
    .zindex-10 {
      z-index: 10;
    }
    .zindex-100 {
      z-index: 100;
    }
    .zindex-1000 {
      z-index: 1000;
    }
    .inline-block {
      display: inline-block;
    }
    footer {
      ul li {
          list-style-type: none;
      }
    }
    ul.disc-style {
      list-style-type: disc;
    }
    .hero {
      padding-top: 5rem;
      padding-bottom: 5rem;
      padding-left: 1rem;
      padding-right: 1rem;
      overflow: hidden;
      --tw-text-opacity: 1;
      color: rgba(255, 255, 255, var(--tw-text-opacity));
      width: 100%;
      @media screen and (max-width: 650px) {
        &.hero-text {
          padding-top: 1rem;
          padding-bottom: 1rem;
        }
        .hero-content .hero-content-wrapper div.hero-content-inner-wrapper h1.hero-header span.header-text {
          font-size: 1.75rem;
          line-height: 1.75rem;
        }
      }
      .description {
        margin-bottom: 1.5rem;
        font-size: 1.3125rem;
        line-height: 2rem;
        font-weight: 400;
        letter-spacing: 0em;
        margin-right: 15%;
        color: #fff;
      }
      .hero-pattern {
        width: 100%;
        height: 100%;
        z-index: -1;
        top: 0;
        left: 0;
        img.swirl {
          position: absolute;
          inset: 0;
          box-sizing: border-box;
          padding: 0;
          border: none;
          margin: auto;
          display: block;
          width: 0;
          height: 0;
          min-width: 100%;
          max-width: 100%;
          min-height: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      }
      .hero-pattern.software-downloads-postgres,
      .hero-pattern.repos,
      .hero-pattern.repos-legacy {
        background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
        --tw-gradient-from: #171845;
        --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to, rgba(23, 24, 69, 0));
        --tw-gradient-to: #732DD9;
      }
      .hero-content {
        margin-left: auto;
        margin-right: auto;
        max-width: 80rem;
        .hero-content-wrapper {
          flex-direction: row;
          .hero-content-inner-wrapper {
            flex: 1 1 0%;
            padding-top: 1rem;
            padding-bottom: 1rem;
            h1.hero-header {
              margin-bottom: 1.25rem;
              @media screen and (max-width: 650px) {
                flex-direction: column;
                span.text-secondary {
                  padding-top: 5px;
                }
              }
              span.header-text {
                font-size: 3rem;
                line-height: 3.25rem;
                font-weight: 300;
                color: #fff;
              }
              span.header-text-bold {
                font-size: 3rem;
                line-height: 3.25rem;
                font-weight: 700;
                color: #fff;
              }
            }
          }

        }
      }
    }
    div.hero.position-mobile-fixed {
      position: relative;
      padding-top: 3rem;
      padding-right: 3rem;
      padding-left: 3rem;
    }
    @media (max-width: 650px) {
      div.hero.position-mobile-fixed {
        /*position: fixed;*/
        padding-top: 1rem;
        padding-bottom: 0;
        padding-right: 1rem;
        padding-left: 1rem;
        width: 100vw;
        .description {
          display: none;
        }
        .hero-content .hero-content-wrapper .hero-content-inner-wrapper h1.hero-header span.header-text {
          font-size: 1.75rem;
          line-height: 1.75rem;
        }
      }
    }
    .fixed-header-pad {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }
    // @media (max-width: 650px) {
    //   .fixed-header-pad {
    //     padding-top: 18rem;
    //   }
    // }
    .announcement {
      padding: .25rem 1rem;
      overflow: hidden;
      --tw-text-opacity: 1;
      color: rgba(255, 255, 255, var(--tw-text-opacity));
      width: 100%;
  
      .description {
        margin-bottom: 1.5rem;
        font-size: 1.3125rem;
        line-height: 2rem;
        font-weight: 400;
        letter-spacing: 0em;
        margin-right: 15%;
        color: #fff;
      }
  
      .hero-pattern {
        width: 100%;
        height: 100%;
        z-index: -1;
        top: 0;
        left: 0;
  
        img.swirl {
          position: absolute;
          inset: 0;
          box-sizing: border-box;
          padding: 0;
          border: none;
          margin: auto;
          display: block;
          width: 0;
          height: 0;
          min-width: 100%;
          max-width: 100%;
          min-height: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      }
  
       .hero-pattern.software-downloads-postgres,
       .hero-pattern.repos,
       .hero-pattern.repos-legacy {
         background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
         --tw-gradient-from: #171845;
         --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(23, 24, 69, 0));
         --tw-gradient-to: #732DD9;
       }
       
       .hero-content {
         margin-left: auto;
         margin-right: auto;
         max-width: 80rem;
  
         .hero-content-wrapper {
           flex-direction: row;
  
           .hero-content-inner-wrapper {
             flex: 1 1 0%;
             padding-top: 1rem;
             padding-bottom: 1rem;

            @media (max-width:650px) {
              flex-direction: column;
              align-items: flex-start !important;
            }

             .title-wrapper {
              margin-right: 1rem;
             }
             .hero-location {
              margin-right: 1rem;
              margin-bottom: .25rem;
             }
             .hero-date {
              margin-right: 1rem;
              margin-bottom: .25rem;
             }
             .hero-button {
              margin-bottom: .6rem;
              white-space: nowrap;
             }
             .pvg-cta {
              padding: .25rem .75rem;
             }
             h1.announcement-header {
              margin-bottom: 0;
               span.header-text {
                 font-size: 3rem;
                 line-height: 3.25rem;
                 font-weight: 300;
                 color: #fff;
               }
  
               span.header-text-bold {
                 font-size: 2.5rem;
                 font-weight: 700;
                 color: #fff;
               }
             }
           }
  
         }
       }
    }
    .hero.hero-text {
      color: #000;
    }
    .hero.hero-text.hero-text-white {
      color: #fff;
    }
    .hero {
      padding: 5rem 1rem;
      overflow: hidden;
      --tw-text-opacity: 1;
      color: rgba(255, 255, 255, var(--tw-text-opacity));
      width: 100%;
      .description {
        margin-bottom: 1.5rem;
        font-size: 1.3125rem;
        line-height: 2rem;
        font-weight: 400;
        letter-spacing: 0em;
        margin-right: 15%;
        color: #fff;
      }
      .hero-pattern {
        width: 100%;
        height: 100%;
        z-index: -1;
        top: 0;
        left: 0;
        img.swirl {
          position: absolute;
          inset: 0;
          box-sizing: border-box;
          padding: 0;
          border: none;
          margin: auto;
          display: block;
          width: 0;
          height: 0;
          min-width: 100%;
          max-width: 100%;
          min-height: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      }
      .hero-pattern.software-downloads-postgres,
      .hero-pattern.repos,
      .hero-pattern.repos-legacy {
        background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
        --tw-gradient-from: #1D3843;
        --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(23, 24, 69, 0));
        --tw-gradient-to: #1E7286;
      }
    }
    .page-section {
      background-color: #fafafa;
      .page-section-inner {
        ul {
          li {
            background-color: #fff;
          }
        }
      }
      .product-overview {
        width: 24rem;
        h2 {
          font-size: 18px !important;
        }
      }
      .product-versions {
        .nav-pills {
          margin-bottom: 30px;
          button {
            line-height: 2;
            transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
            transition-timing-function: linear;
            transition-duration: 100ms;
            font-weight: 600;
            padding-left: 2rem;
            padding-right: 2rem;
            margin: 0px;
            padding-top: 2px;
            padding-bottom: 2px;
          }
          button.active {
            color: rgba(0, 0, 0, var(--tw-text-opacity));
            border: 1px solid rgba(124.03199999999998, 135.1704, 141.16800000000003, var(--tw-border-opacity));
            --tw-border-opacity: 1;
            --tw-bg-opacity: 1;
            color: rgba(34, 31, 31, var(--tw-text-opacity));
            background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
            border-radius: 9999px;
            &:empty {
              border: 0;
            }
          }
        }
        .tab-content {
          .sub-versions-wrapper {
            min-height: 50px;
            .os-icon {
              font-size: 2.25rem;
              line-height: 2.5rem;
              margin-top: 0.25rem;
              margin-right: 1rem;
            }
          }
          .installer-btn {
            font-weight: 500;
          }
          ul {
            li {
              &:last-child {
                border-bottom: none !important;
              }
            }
          }
        }
      }
    }
    .justify-content-mobile {justify-content: space-between;}
    #repo1-card, #repo2-card {
      @media screen and (max-width: 650px) {
        .justify-content-mobile,
        .justify-end-mobile {
            justify-content: flex-end;
        }
        .mobile-column {
          flex-direction: column;
        }
      }
    }
    .repo-creds {
      font-family: 'Courier New', Courier, monospace;
      a {
        font-family: 'Courier New', Courier, monospace;
      }
      .repo-cred-hidden {
        font-size: 32px;
      }
      .repo-cred-shown {
        margin-top: 4px;
        display: inline-block;
      }
    }
    .repo-creds.repo-two {
      .repo-cred-hidden {
        letter-spacing: -8px;
      }
    }
    .repo-creds.repo-legacy {
      .repo-cred-hidden {
        letter-spacing: -8px;
      }
    }
    .edb-btn {
      position: relative;
      z-index: 2;
      font-size: 1rem;
      line-height: 1.5rem;
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
      display: inline-flex;
      -moz-box-align: center;
      align-items: center;
      -moz-box-pack: center;
      justify-content: center;
      padding: 0.5rem 1.25rem;
      border-width: 1px;
      border-radius: 9999px;
      font-weight: 600;
      --tw-text-opacity: 1;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        --tw-bg-opacity: 1;
        background-color: rgba(193.8, 48.45, 0, var(--tw-bg-opacity));
      }
    }
    .edb-btn.teal,
    .edb-btn.teal.primary {
      background-color: #1B8392;
      color: rgba(255, 255, 255, var(--tw-text-opacity));
      border-color: transparent;
    }
    .edb-btn.orange.secondary {
      background-color: transparent;
      color: #1B8392;
      border-color: #1B8392;
      border-style: solid;
      font-weight: 400;
    }
    .edb-btn.orange,
    .edb-btn.orange.primary {
      background-color: #ff4000;
      color: rgba(255, 255, 255, var(--tw-text-opacity));
      border-color: transparent;
    }
    .edb-btn.white,
    .edb-btn.white.primary {
      background-color: #fff;
      color: #221f1f;
      border-color: transparent;
    }
    .edb-btn.orange.secondary {
      background-color: transparent;
      color: #ff4000;
      border-color: #ff4000;
      border-style: solid;
      font-weight: 400;
    }
    .edb-btn.white.secondary {
      background-color: transparent;
      color: #fff;
      border-color: #fff;
      border-style: solid;
      font-weight: 600;
      &:hover {
        color: #000;
        background-color: #fff;;
      }
    }
    .edb-btn.link {
      background-color: transparent;
      font-weight: 400;
      &::after {
        content: "→";
        margin-left: 5px;
      }
    }
    .edb-btn.white.link {
      color: #fff;
    }
    .edb-btn.orange.link {
      color: #ff4000;
    }

    .expired-alert {
      background-color: #ffe4d6 !important;
      border-color: rgb(255, 64, 0) !important;
    }
    .error-alert {
      background-color: #ffe4d6 !important;
      border-color: #ffe4d6 !important;
    }
    .repo-choices {
      min-width: 400px;
      max-width: 400px;
      @media (max-width: 650px) {
        min-width: 0;
        width: 100%;
      }
        
      .form-check-input[type=checkbox] {
        border-radius: 0 !important;
        width: 22px;
        height: 22px;
        &:checked {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-check' viewBox='0 0 16 16'%3E%3Cpath d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'/%3E%3C/svg%3E") !important;
          background-color: #ddd;
          border: 2px solid #ddd;
          background-size: 135%;
        }
      }
    }
    .repos-instructions {
      .instructions-subheader {
        font-size: 18px;
        font-weight: 600;
      }
      pre {
        border-radius: 0.75rem;
        padding: 20px !important;
      }
      .copy-script {
        font-size: 0.75rem;
        line-height: 1rem;
      }
    }
    .instructions-wrapper {
      .spinner-border {
        position: fixed !important;
        top: 50% !important;
        z-index: 2;
      }
      .instructions {
        position: sticky;
        top: 10px;
      }
    }
    .tooltip-btn {
      width: 40px;
    }
    .tooltip-btn.tooltip-token {
      margin-top: -10px;
      padding-left: 0;
      padding-right: 0;
    }
    table {
      tr {
        th.version-col {
          font-size: 1.25rem !important;
        }
        td.row-no-border {
          border-bottom-width: 0 !important;
        }
        td.cell-section {
          padding: 2rem 1.5rem 1rem 0px;
          white-space: nowrap;
          text-align: left;
          font-weight: 600;
          font-size: 1.125rem;
          line-height: 1.75rem;
        }
        td {
          span.cell-indent {
            padding: 0.5rem 1.5rem;
          }
        }
        td {
          // &:last-child {
          //   border: none !important;
          // }
        }
      }
      tr {
        &:last-child {
          td {
            border-bottom: none !important;
            border-top: 1px solid rgb(221, 220, 220)
          }
        }
      }
    }
    table.table.platform-compatiblity-table {

      td.column-1 {
        table-layout: fixed;
        word-wrap: normal;
        
        >span {
          word-wrap: normal;
          width: inherit;
        }
      }
    }
    .card-pad {
      padding-right: 1rem;
      padding-left: 1rem;
    }
    .card-body button h5 {
      overflow-wrap: break-word;
    }
    @media (max-width: 650px) {
      .compatiblity-content {
        overflow: scroll;
        min-width: 650px;
      }
    }
    .compatiblity-content {
      margin-left: auto;
      margin-right: auto;
      max-width: 80rem;
    }

    .nav-flex {
      display: flex;
    }
    @media (max-width: 720px) {
      .nav-flex {
        flex-direction: column;
        a {
          margin-bottom: 5px;
          width: 100%;
        }
      }
    }
    .mt-4-mobile {
      margin-top: 1.5rem;
    }
    /*** Mobile Rules ***/
    @media (max-width: 650px) {
      .mt-4-mobile {margin-top: 0;}
      .pt-3-mobile {
        padding-top: 1rem;
      }
      .py-3-mobile {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
      .pr-5-mobile {
        padding-right: 3rem;
      }
      .card {
        margin: 0;
        .card-body {
          width: 100%;
          padding: 0;
        }
      }
      div.repo-select-wrapper {
        flex-direction: column;
      }
      .repo-choices {
        padding-left: 5px;
        padding-right: 5px;
      }
      .px-3-mobile {
        padding-left: 0;
        padding-right: 0;
      }
      div.me-4-mobile {
        margin-right: 0;
      }
      .mobile-flex-column {
        flex-direction: column;
      }
    }
    .px-3-mobile {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .me-4-mobile {
      margin-right: 1.5rem;
    }

    /*** static page styles mobile ***/
    @media (max-width: 650px) {
      .css-rq7hdw { margin-top: 0;}
      .css-8oh6lz {font-size: 1.75rem;}
      table.community-pg-downloads {
        .css-x01ejq, .css-18b9jja {
          font-size: .9rem;
          line-height: 1rem;
          padding-right: 2px;
        }
        .css-lk2yyo {
          padding-right: 2px;
        }
      }
    }

  }

  }
  .auth-message {
    --tw-bg-opacity: 0.8;
    background-color: #08293a;
    margin-bottom: 0px;
    text-align: center;
    border-radius: 0.5rem;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .link-orange {
    color: rgb(255, 64, 0);
    &:hover,
    &:visited {
      color: rgb(255, 64, 0);
    }
  }
  .link-teal {
    color: #0A8A8A;
      &:hover,
      &:visited {
        color: #0A8A8A;
        filter: brightness(90%);
      }
  }
// Fonts
/**
 * @license
 *
 * Font Family: Supreme
 * Designed by: Jérémie Hornus, Ilya Naumoff
 * URL: https://www.fontshare.com/fonts/supreme
 * © 2021 Indian Type Foundry
 */

 @font-face {
  font-family: "Supreme-Variable";
  src: url("./fonts/Supreme-Variable.woff2") format("woff2"),
    url("./fonts/Supreme-Variable.woff") format("woff");
  font-weight: 100 800;
  font-display: swap;
  font-style: normal;
}

/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 800.0;
*
* available axes:

* 'wght' (range from 100.0 to 800.0)

*/

@font-face {
  font-family: "Supreme-VariableItalic";
  src: url("./fonts/Supreme-VariableItalic.woff2") format("woff2"),
    url("./fonts/Supreme-VariableItalic.woff") format("woff");
  font-weight: 100 800;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Industry";
  src: url("./fonts/Industry-Bold.woff") format("woff2"),
    url("./fonts/Industry-Bold.woff") format("woff");
  font-weight: bold;
  font-display: swap;
  font-style: normal;
}

/**
* Roboto
*/
@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto-Thin.ttf") format("truetype");
  font-weight: 100;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
.fpx-18 {
  font-size: 18px;
}
a.fwt-500,
.fwt-500 {
  font-weight: 500;
}
a.fwt-400,
.fwt-400 {
  font-weight: 400;
}
a.fwt-300,
.fwt-300 {
  font-weight: 300;
}
a.fwt-200,
.fwt-200 {
  font-weight: 200;
}

.banner-video {
  width:100%;
}
/*** utility styles ***/
.no-show {
  display: none;
}
.white-fill {
  fill: #fff;
}
.ml-half {
  margin-left: .5rem;
}
.ml-50 {
  margin-left: 50px;
}
.mr-50 {
  margin-right: 50px;
}
.mt-25 {
  margin-top: 25px;
}

.mt-50 {
  margin-top: 50px;
}

.width-10 {
  width: 10%;
}

.width-15 {
  width: 15%;
}

.width-20 {
  width: 20%;
}

.width-25 {
  width: 25%;
}

.width-30 {
  width: 30%;
}

.width-100 {
  width: 100%;
}

.width-50 {
  width: 50%;
}
.fix-120 {
  width: 120px;
}
.fix-200 {
  width: 200px;
}
.fix-100 {
  width: 100px;
}
.btn-orange-outline {
  font-size: 1rem;
  line-height: 1.5rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0.5rem 1.25rem;
  background-color: transparent;
  border: 1px solid;
  border-radius: 9999px;
  font-weight: 600;
  --tw-border-opacity: 1;
  border-color: rgba(255, 63.75, 0, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 63.75, 0, var(--tw-text-opacity));
}
.btn-teal-outline {
  font-size: 1rem;
  line-height: 1.5rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0.5rem 1.25rem;
  background-color: transparent;
  border: 1px solid;
  border-radius: 9999px;
  font-weight: 600;
  --tw-border-opacity: 1;
  border-color: #1E91A2;
  --tw-text-opacity: 1;
  color: #1E91A2;
  &:hover {
    color: #1E91A2;
    filter: brightness(90%);
  }
}
.btn-white-outline {
  font-size: 1rem;
  line-height: 1.5rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0.5rem 2.5rem;
  background-color: transparent;
  border: 1px solid;
  border-radius: 9999px;
  font-weight: 600;
  --tw-border-opacity: 1;
  border-color: #fff;
  --tw-text-opacity: 1;
  color: #fff;
  &:hover {
    color: #fff;
    filter: brightness(90%);
  }
}
.cover-fit-100 {
  object-fit: cover;
  width: 100%;
  height: 100px;
}
.flex-flex-start {
  display: flex;
  align-items: flex-start;
}

.double-arrow {
  width: 0.625rem;
  display: inline;
}

.font-weight-300 {
  font-weight: 300;
}